/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const onCreateSystemLogConnection = /* GraphQL */ `
  subscription OnCreateSystemLogConnection {
    onCreateSystemLogConnection {
      id
      LastHeartbeat
      LastAlert
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateSystemLogConnection = /* GraphQL */ `
  subscription OnUpdateSystemLogConnection {
    onUpdateSystemLogConnection {
      id
      LastHeartbeat
      LastAlert
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteSystemLogConnection = /* GraphQL */ `
  subscription OnDeleteSystemLogConnection {
    onDeleteSystemLogConnection {
      id
      LastHeartbeat
      LastAlert
      createdAt
      updatedAt
    }
  }
`;
export const onCreateCipMohHealthcheck = /* GraphQL */ `
  subscription OnCreateCipMohHealthcheck {
    onCreateCipMohHealthcheck {
      id
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateCipMohHealthcheck = /* GraphQL */ `
  subscription OnUpdateCipMohHealthcheck {
    onUpdateCipMohHealthcheck {
      id
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteCipMohHealthcheck = /* GraphQL */ `
  subscription OnDeleteCipMohHealthcheck {
    onDeleteCipMohHealthcheck {
      id
      createdAt
      updatedAt
    }
  }
`;
export const onCreateCipMohHeartbeat = /* GraphQL */ `
  subscription OnCreateCipMohHeartbeat {
    onCreateCipMohHeartbeat {
      id
      emeStatus
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateCipMohHeartbeat = /* GraphQL */ `
  subscription OnUpdateCipMohHeartbeat {
    onUpdateCipMohHeartbeat {
      id
      emeStatus
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteCipMohHeartbeat = /* GraphQL */ `
  subscription OnDeleteCipMohHeartbeat {
    onDeleteCipMohHeartbeat {
      id
      emeStatus
      createdAt
      updatedAt
    }
  }
`;
