import { Logger } from "aws-amplify";
import TableHealthcheck from "./TableHealthchecks";
import TableHeartbeat from "./TableHeartbeats";

function MOHCIP() {
  logger.debug("rendered");

  return (
    <div>
      <h1 className="display-4">MOH CIP Agency Dashboard</h1>

      <p className="lead">
        Realtime healthcheck and heartbeat monitor for MOH CIP Interface
      </p>

      <TableHealthcheck />

      <TableHeartbeat />
    </div>
  );
}

const logger = new Logger("components/MOHCIP/MOHCIP.js");

export default MOHCIP;
