import { API, Logger } from "aws-amplify";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import Table from "react-bootstrap/Table";
import Countdown from "react-countdown";
import { listCipMohHealthchecks } from "../../graphql/queries";
import {
  onCreateCipMohHealthcheck,
  onDeleteCipMohHealthcheck,
  onUpdateCipMohHealthcheck,
} from "../../graphql/subscriptions";

function TableHealthcheck() {
  const [rows, setRows] = useState([]);

  const isLongerThan20Seconds = (val) => {
    let val_dayjs = dayjs(val);

    if (!val_dayjs.isValid()) {
      return false;
    }

    let now_dayjs = dayjs();
    let diff = now_dayjs.diff(val_dayjs, "second");

    return diff > 20;
  };

  const SortRowsByLastHealthcheck = (rows) => {
    return rows.sort((a, b) => dayjs(a.updatedAt) - dayjs(b.updatedAt));
  };

  // Get initial rows
  useEffect(() => {
    API.graphql({ query: listCipMohHealthchecks, authMode: "API_KEY" })
      .then((res) => {
        setRows(
          SortRowsByLastHealthcheck(res.data.listCipMohHealthchecks.items)
        );
      })
      .catch((e) => {
        logger.error(e);
      });
  }, []);

  // Subscriptions
  useEffect(() => {
    let sub_row_created = API.graphql({
      query: onCreateCipMohHealthcheck,
      authMode: "API_KEY",
    }).subscribe(
      (res) => {
        setRows((prevState) =>
          SortRowsByLastHealthcheck([
            ...prevState,
            res.value.data.onCreateCipMohHealthcheck,
          ])
        );
      },
      (e) => {
        logger.error(e);
      }
    );

    let sub_row_updated = API.graphql({
      query: onUpdateCipMohHealthcheck,
      authMode: "API_KEY",
    }).subscribe(
      (res) => {
        setRows((prevState) =>
          SortRowsByLastHealthcheck([
            ...prevState.map((r) => {
              if (res.value.data.onUpdateCipMohHealthcheck.id === r.id) {
                Object.assign(r, res.value.data.onUpdateCipMohHealthcheck);
              }
              return r;
            }),
          ])
        );
      },
      (e) => {
        logger.error(e);
      }
    );

    let sub_row_deleted = API.graphql({
      query: onDeleteCipMohHealthcheck,
      authMode: "API_KEY",
    }).subscribe(
      (res) => {
        setRows((prevState) =>
          SortRowsByLastHealthcheck([
            ...prevState.filter(
              (r) => r.id !== res.value.data.onDeleteCipMohHealthcheck.id
            ),
          ])
        );
      },
      (e) => {
        logger.error(e);
      }
    );

    return () => {
      if (sub_row_created && sub_row_created.unsubscribe) {
        sub_row_created.unsubscribe();
      }

      if (sub_row_updated && sub_row_updated.unsubscribe) {
        sub_row_updated.unsubscribe();
      }

      if (sub_row_deleted && sub_row_deleted.unsubscribe) {
        sub_row_deleted.unsubscribe();
      }
    };
  }, []);

  logger.debug("rendered");

  return (
    <Table responsive>
      <thead>
        <tr>
          <th colSpan={2}>
            <span className="display-5">MOH Healthchecks</span>
          </th>
        </tr>

        <tr>
          <th colSpan={2}>
            <Countdown
              key={(Math.random() + 1).toString(36).substring(4)}
              date={Date.now() + 60000}
              intervalDelay={0}
              precision={3}
              renderer={(props) => (
                <span>
                  {props.total > 0
                    ? `Will refresh in ${props.total} seconds`
                    : "Refreshing now..."}
                </span>
              )}
              onComplete={() => {
                setRows((prevState) => {
                  return [...prevState];
                });
              }}
            />
          </th>
        </tr>

        <tr>
          <th>Environment</th>
          <th>Last Healthcheck</th>
        </tr>
      </thead>

      <tbody>
        {rows.map((r, i) => {
          let last_healtchcheck_dayjs = dayjs(r.updatedAt);

          return (
            <tr
              key={i}
              className={
                isLongerThan20Seconds(r.updatedAt)
                  ? "table-danger"
                  : "table-success"
              }
            >
              <td>{r.id}</td>
              <td>
                {last_healtchcheck_dayjs.isValid()
                  ? last_healtchcheck_dayjs.format("ddd, MMM D, YYYY h:mm A")
                  : "INVALID DATE"}
              </td>
            </tr>
          );
        })}
      </tbody>
    </Table>
  );
}

const logger = new Logger("components/MOHCIP/TableHealthcheck.js");

export default TableHealthcheck;
