import { Logger } from "aws-amplify";
import { useState } from "react";
import Nav from "react-bootstrap/Nav";
import TableNetSyncConnections from "./TableNetSyncConnections";
import TableNetSyncSystems from "./TableNetSyncSystems";

function NetSync() {
  const [activeKey, setActiveKey] = useState(ENUM_ACTIVE_KEY.CONNECTIONS);

  /**
   * Nav On Select
   * @param { string } eventKey
   */
  const onSelect = (eventKey) => {
    setActiveKey(eventKey);
  };

  logger.debug("rendered");

  return (
    <div>
      <h1 className="display-4">NetSync Agency Dashboard</h1>

      <p className="lead">
        Tracking EME Systems with Connections and Offline Systems
      </p>

      <Nav
        variant="pills"
        activeKey={activeKey}
        onSelect={onSelect}
        className="mb-1"
      >
        <Nav.Item>
          <Nav.Link eventKey={ENUM_ACTIVE_KEY.CONNECTIONS}>
            List Connections
          </Nav.Link>
        </Nav.Item>

        <Nav.Item>
          <Nav.Link eventKey={ENUM_ACTIVE_KEY.SYSTEMS}>
            List Offline Systems
          </Nav.Link>
        </Nav.Item>
      </Nav>

      {activeKey === ENUM_ACTIVE_KEY.CONNECTIONS ? (
        <TableNetSyncConnections />
      ) : null}

      {activeKey === ENUM_ACTIVE_KEY.SYSTEMS ? <TableNetSyncSystems /> : null}
    </div>
  );
}

const logger = new Logger("components/NetSync/NetSync.js");

export const ENUM_ACTIVE_KEY = Object.freeze({
  CONNECTIONS: "connections",
  SYSTEMS: "systems",
});

export default NetSync;
