import { Logger } from "aws-amplify";
import { useCallback, useEffect, useState } from "react";
import Table from "react-bootstrap/Table";
import Countdown from "react-countdown";

function TableNetSyncSystems() {
  const [rows, setRows] = useState([]);

  const getRows = async (sig) => {
    return new Promise(async (resolve, reject) => {
      try {
        let url =
          "https://exwqzdbh95.execute-api.us-east-1.amazonaws.com/Production/netsync-systems-offline";
        let options = {
          method: "get",
          signal: sig,
          "Access-Control-Allow-Credentials": true,
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
        };
        let res = await window.fetch(url, options);

        res.json ? resolve(res.json()) : reject(new Error(JSON.stringify(res)));
      } catch (e) {
        reject(e);
      }
    });
  };

  const onLoad = useCallback(async (sig) => {
    let rowsFromDb = await getRows(sig);

    return rowsFromDb;
  }, []);

  useEffect(() => {
    const signal = controller.signal;

    signal.addEventListener("abort", () => {
      logger.log("get rows aborted!");
    });

    onLoad(signal).then(setRows).catch(logger.error);

    // Clean up
    return () => {
      signal && signal.abort ? signal.abort() : logger.info("Clear exit");
    };
  }, [onLoad]);

  logger.debug("TableNetSyncSystems rendered");

  return (
    <Table responsive>
      <thead>
        <tr>
          <th colSpan={3}>
            <Countdown
              key={(Math.random() + 1).toString(36).substring(4)}
              date={Date.now() + 60000}
              intervalDelay={0}
              precision={3}
              renderer={(props) => (
                <span>
                  {props.total > 0
                    ? `Will refresh in ${props.total} seconds`
                    : "Refreshing now..."}
                </span>
              )}
              onComplete={() => {
                const signal = controller.signal;

                signal.addEventListener("abort", () => {
                  logger.log("get rows aborted!");
                });

                onLoad(signal).then(setRows).catch(logger.error);
              }}
            />
          </th>
        </tr>

        <tr>
          <th>Name</th>
          <th>System Name</th>
          <th>Status</th>
        </tr>
      </thead>

      <tbody>
        {rows.map((r, i) => {
          return (
            <tr key={i}>
              <td>{r.sName}</td>
              <td>{r.sSystemName}</td>
              <td>{r.sStatus}</td>
            </tr>
          );
        })}
      </tbody>
    </Table>
  );
}

const logger = new Logger("components/NetSync/TableNetSyncSystems.js");
const controller = new window.AbortController();

export default TableNetSyncSystems;
