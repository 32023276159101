import { API, Logger } from "aws-amplify";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import Table from "react-bootstrap/Table";
import Countdown from "react-countdown";
import { listCipMohHeartbeats } from "../../graphql/queries";
import {
  onCreateCipMohHeartbeat,
  onDeleteCipMohHeartbeat,
  onUpdateCipMohHeartbeat,
} from "../../graphql/subscriptions";

function TableHeartbeat() {
  const [rows, setRows] = useState([]);

  const isLongerThan60Seconds = (val) => {
    let val_dayjs = dayjs(val);

    if (!val_dayjs.isValid()) {
      return false;
    }

    let now_dayjs = dayjs();
    let diff = now_dayjs.diff(val_dayjs, "second");

    logger.debug({
      now_dayjs: now_dayjs.format(),
      val_dayjs: val_dayjs.format(),
      diff,
    });

    return diff > 60;
  };

  const SortRowsByLastHeartbeat = (rows) => {
    return rows.sort((a, b) => dayjs(a.updatedAt) - dayjs(b.updatedAt));
  };

  // Get initial rows
  useEffect(() => {
    API.graphql({ query: listCipMohHeartbeats, authMode: "API_KEY" })
      .then((res) => {
        setRows(SortRowsByLastHeartbeat(res.data.listCipMohHeartbeats.items));
      })
      .catch((e) => {
        logger.error(e);
      });
  }, []);

  // Subscriptions
  useEffect(() => {
    let sub_row_created = API.graphql({
      query: onCreateCipMohHeartbeat,
      authMode: "API_KEY",
    }).subscribe(
      (res) => {
        setRows((prevState) =>
          SortRowsByLastHeartbeat([
            ...prevState,
            res.value.data.onCreateCipMohHeartbeat,
          ])
        );
      },
      (e) => {
        logger.error(e);
      }
    );

    let sub_row_updated = API.graphql({
      query: onUpdateCipMohHeartbeat,
      authMode: "API_KEY",
    }).subscribe(
      (res) => {
        setRows((prevState) =>
          SortRowsByLastHeartbeat([
            ...prevState.map((r) => {
              if (res.value.data.onUpdateCipMohHeartbeat.id === r.id) {
                Object.assign(r, res.value.data.onUpdateCipMohHeartbeat);
              }
              return r;
            }),
          ])
        );
      },
      (e) => {
        logger.error(e);
      }
    );

    let sub_row_deleted = API.graphql({
      query: onDeleteCipMohHeartbeat,
      authMode: "API_KEY",
    }).subscribe(
      (res) => {
        setRows((prevState) =>
          SortRowsByLastHeartbeat([
            ...prevState.filter(
              (r) => r.id !== res.value.data.onDeleteCipMohHeartbeat.id
            ),
          ])
        );
      },
      (e) => {
        logger.error(e);
      }
    );

    return () => {
      if (sub_row_created && sub_row_created.unsubscribe) {
        sub_row_created.unsubscribe();
      }

      if (sub_row_updated && sub_row_updated.unsubscribe) {
        sub_row_updated.unsubscribe();
      }

      if (sub_row_deleted && sub_row_deleted.unsubscribe) {
        sub_row_deleted.unsubscribe();
      }
    };
  }, []);

  logger.debug("rendered");

  return (
    <Table responsive>
      <thead>
        <tr>
          <th colSpan={3}>
            <span className="display-5">EME Heartbeats</span>
          </th>
        </tr>

        <tr>
          <th colSpan={3}>
            <Countdown
              key={(Math.random() + 1).toString(36).substring(4)}
              date={Date.now() + 60000}
              intervalDelay={0}
              precision={3}
              renderer={(props) => (
                <span>
                  {props.total > 0
                    ? `Will refresh in ${props.total} seconds`
                    : "Refreshing now..."}
                </span>
              )}
              onComplete={() => {
                setRows((prevState) => {
                  return [...prevState];
                });
              }}
            />
          </th>
        </tr>

        <tr>
          <th>Agency</th>
          <th>Last Heartbeat</th>
          <th>Last EME Status</th>
        </tr>
      </thead>

      <tbody>
        {rows.map((r, i) => {
          let last_heartbeat_dayjs = dayjs(r.updatedAt);

          return (
            <tr
              key={i}
              className={
                isLongerThan60Seconds(r.updatedAt)
                  ? "table-danger"
                  : "table-success"
              }
            >
              <td>{r.id}</td>
              <td>
                {last_heartbeat_dayjs.isValid()
                  ? last_heartbeat_dayjs.format("MMM D, YYYY h:mm:ss A")
                  : "INVALID DATE"}
              </td>
              <td>{r.emeStatus === "true" ? "Connected" : "Disconnected"}</td>
            </tr>
          );
        })}
      </tbody>
    </Table>
  );
}

const logger = new Logger("components/MOHCIP/TableHeartbeat.js");

export default TableHeartbeat;
