// third party
import Amplify, { Logger } from 'aws-amplify';
import { StrictMode } from 'react';
import { render } from 'react-dom';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import localizedFormat from 'dayjs/plugin/localizedFormat';

// local
import awsExports from './aws-exports';

// css
import './index.css';

// NOTE: called last so that CSS above is baseline and component CSS can override
import App from './App';

// Load Amplify Config
Amplify.configure({
  ...awsExports,
  ssr: true,
  Analytics: {

    disabled: process.env.NODE_ENV !== 'production' ? false : true,
  }
}); // https://docs.amplify.aws/lib/ssr/q/platform/js/#enabling-ssr

// Set log level
if (process.env.NODE_ENV !== 'production') {

  Amplify.Logger.LOG_LEVEL = 'INFO';
}

// set DayJS
dayjs.extend(relativeTime);
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.tz.guess();
dayjs.extend(localizedFormat);

const logger = new Logger('index.js');
const root = document.getElementById('root');

// if (!!window.SharedWorker) {

//   logger.info('Setting up SharedWorker');

//   const worker = new SharedWorker('/shared-worker.js');

//   worker.port.start();

//   worker.port.onmessage = function (event) {

//     logger.info('Got: ' + event.data + '\n');
//   };

//   worker.port.onerror = function (error) {

//     logger.error('Worker error: ' + error.message + '\n');
//   };

//   worker.port.postMessage({ msg: 'Testing' });
// }

logger.debug('rendered');

render(
  <StrictMode>

    <App />
  </StrictMode>,
  root
);
