/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getSystemLogConnection = /* GraphQL */ `
  query GetSystemLogConnection($id: ID!) {
    getSystemLogConnection(id: $id) {
      id
      LastHeartbeat
      LastAlert
      createdAt
      updatedAt
    }
  }
`;
export const listSystemLogConnections = /* GraphQL */ `
  query ListSystemLogConnections(
    $filter: ModelSystemLogConnectionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSystemLogConnections(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        LastHeartbeat
        LastAlert
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getCipMohHealthcheck = /* GraphQL */ `
  query GetCipMohHealthcheck($id: ID!) {
    getCipMohHealthcheck(id: $id) {
      id
      createdAt
      updatedAt
    }
  }
`;
export const listCipMohHealthchecks = /* GraphQL */ `
  query ListCipMohHealthchecks(
    $filter: ModelCipMohHealthcheckFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCipMohHealthchecks(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getCipMohHeartbeat = /* GraphQL */ `
  query GetCipMohHeartbeat($id: ID!) {
    getCipMohHeartbeat(id: $id) {
      id
      emeStatus
      createdAt
      updatedAt
    }
  }
`;
export const listCipMohHeartbeats = /* GraphQL */ `
  query ListCipMohHeartbeats(
    $filter: ModelCipMohHeartbeatFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCipMohHeartbeats(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        emeStatus
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
