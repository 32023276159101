import { Logger } from "aws-amplify";
import dayjs from "dayjs";
import { useCallback, useEffect, useState } from "react";
import Table from "react-bootstrap/Table";
import Countdown from "react-countdown";

function TableNetSyncConnections() {
  const [rows, setRows] = useState([]);

  const getRows = async (sig) => {
    return new Promise(async (resolve, reject) => {
      try {
        let url =
          "https://exwqzdbh95.execute-api.us-east-1.amazonaws.com/Production/netsync-list-connections";
        let options = {
          method: "get",
          signal: sig,
          cache: "no-cache",
          "Access-Control-Allow-Credentials": true,
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
        };
        let res = await window.fetch(url, options);

        res.json ? resolve(res.json()) : reject(new Error(JSON.stringify(res)));
      } catch (e) {
        reject(e);
      }
    });
  };

  const onLoad = useCallback(async (sig) => {
    let rowsFromDb = await getRows(sig);
    // let rows = [];

    // for (const row of rowsFromDb) {

    //   let d = new Date(row.tSyncStatusCheck);

    //   rows.push({

    //     ...row,
    //     tSyncStatusCheck: `${d.toLocaleDateString()} ${d.toLocaleTimeString()}`
    //   });
    // }

    return rowsFromDb;
  }, []);

  useEffect(() => {
    const signal = controller.signal;

    signal.addEventListener("abort", () => {
      logger.log("get rows aborted!");
    });

    onLoad(signal).then(setRows).catch(logger.error);

    // Clean up
    return () => {
      signal && signal.abort ? signal.abort() : logger.info("Clear exit");
    };
  }, [onLoad]);

  logger.debug("TableNetSyncConnections rendered");

  return (
    <Table responsive>
      <thead>
        <tr>
          <th colSpan={2}>
            <Countdown
              key={(Math.random() + 1).toString(36).substring(4)}
              date={Date.now() + 60000}
              intervalDelay={0}
              precision={3}
              renderer={(props) => (
                <span>
                  {props.total > 0
                    ? `Will refresh in ${props.total} seconds`
                    : "Refreshing now..."}
                </span>
              )}
              onComplete={() => {
                const signal = controller.signal;

                signal.addEventListener("abort", () => {
                  logger.log("get rows aborted!");
                });

                onLoad(signal).then(setRows).catch(logger.error);
              }}
            />
          </th>
        </tr>

        <tr>
          <th>Name</th>
          <th>Time</th>
        </tr>
      </thead>

      <tbody>
        {rows.map((r, i) => {
          // NOTE RDS is converting this to UTC so adding .000Z to the end of the real time
          // only way to show the proper time
          let sD = `${r.tSyncStatusCheck}`;
          let d = dayjs(sD.substring(0, sD.length - 5), "America/New_York"); // This is always coming from the east coast

          let now = dayjs();
          let dif = now.diff(d, "m");

          return (
            <tr className={dif > 15 ? "table-danger" : "table-success"} key={i}>
              <td>{r.sName}</td>
              <td>{d.format("ddd, MMM D, YYYY h:mm A")}</td>
            </tr>
          );
        })}
      </tbody>
    </Table>
  );
}

const logger = new Logger("components/NetSync/TableNetSyncConnections.js");
const controller = new window.AbortController();

export default TableNetSyncConnections;
