import { API, Logger } from "aws-amplify";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import Table from "react-bootstrap/Table";
import Countdown from "react-countdown";
import { listSystemLogConnections } from "../../graphql/queries";
import {
  onCreateSystemLogConnection,
  onDeleteSystemLogConnection,
  onUpdateSystemLogConnection,
} from "../../graphql/subscriptions";

function SystemLog() {
  const [rows, setRows] = useState([]);

  const SortRowsByLastHeartbeat = (rows) => {
    return rows.sort((a, b) => dayjs(a.LastHeartbeat) - dayjs(b.LastHeartbeat));
  };

  // Get initial rows
  useEffect(() => {
    API.graphql({ query: listSystemLogConnections, authMode: "API_KEY" })
      .then((res) => {
        setRows(
          SortRowsByLastHeartbeat(res.data.listSystemLogConnections.items)
        );
      })
      .catch((e) => {
        logger.error(e);
      });
  }, []);

  // Subscriptions
  useEffect(() => {
    let sub_connections_create = API.graphql({
      query: onCreateSystemLogConnection,
      authMode: "API_KEY",
    }).subscribe(
      (res) => {
        setRows((prevState) =>
          SortRowsByLastHeartbeat([
            ...prevState,
            res.value.data.onCreateSystemLogConnection,
          ])
        );
      },
      (e) => {
        logger.error(e);
      }
    );

    let sub_connections_update = API.graphql({
      query: onUpdateSystemLogConnection,
      authMode: "API_KEY",
    }).subscribe(
      (res) => {
        setRows((prevState) =>
          SortRowsByLastHeartbeat([
            ...prevState.map((r) => {
              if (res.value.data.onUpdateSystemLogConnection.id === r.id) {
                Object.assign(r, res.value.data.onUpdateSystemLogConnection);
              }

              return r;
            }),
          ])
        );
      },
      (e) => {
        logger.error(e);
      }
    );

    let sub_connections_delete = API.graphql({
      query: onDeleteSystemLogConnection,
      authMode: "API_KEY",
    }).subscribe(
      (res) => {
        setRows((prevState) =>
          SortRowsByLastHeartbeat([
            ...prevState.filter(
              (r) => r.id !== res.value.data.onDeleteSystemLogConnection.id
            ),
          ])
        );
      },
      (e) => {
        logger.error(e);
      }
    );

    return () => {
      if (sub_connections_create && sub_connections_create.unsubscribe) {
        sub_connections_create.unsubscribe();
      }

      if (sub_connections_update && sub_connections_update.unsubscribe) {
        sub_connections_update.unsubscribe();
      }

      if (sub_connections_delete && sub_connections_delete.unsubscribe) {
        sub_connections_delete.unsubscribe();
      }
    };
  }, []);

  logger.debug("rendered");

  return (
    <div>
      <h1 className="display-4">System Log Agency Dashboard</h1>

      <p className="lead">
        Realtime healthcheck monitor for system log application
      </p>

      <Table responsive>
        <thead>
          <tr>
            <th colSpan={3}>
              <Countdown
                key={(Math.random() + 1).toString(36).substring(4)}
                date={Date.now() + 60000}
                intervalDelay={0}
                precision={3}
                renderer={(props) => (
                  <span>
                    {props.total > 0
                      ? `Will refresh times in ${props.total} seconds`
                      : "Refreshing now..."}
                  </span>
                )}
                onComplete={() => {
                  setRows((prevState) => [...prevState]);
                }}
              />
            </th>
          </tr>

          <tr>
            <th>Agency</th>
            <th>Last Heartbeat</th>
            <th>Last Alert</th>
          </tr>
        </thead>

        <tbody>
          {rows.map((r, i) => {
            let agency_display = `${r.id}`
              .trim()
              .toUpperCase()
              .split("-")
              .join(" ");
            let last_heartbeat_dayjs = dayjs(r.LastHeartbeat);
            let last_alert_dayjs = dayjs(r.LastAlert);

            return (
              <tr
                key={i}
                className={
                  last_alert_dayjs.isValid() ? "table-danger" : "table-success"
                }
              >
                <td>{agency_display}</td>
                <td>
                  {last_heartbeat_dayjs.isValid()
                    ? last_heartbeat_dayjs.fromNow()
                    : "INVALID DATE"}
                </td>
                <td>
                  {last_alert_dayjs.isValid()
                    ? last_alert_dayjs.format("ddd, MMM D, YYYY h:mm A")
                    : ""}
                </td>
              </tr>
            );
          })}
        </tbody>
      </Table>
    </div>
  );
}

const logger = new Logger("components/SystemLog/SystemLog.js");

export default SystemLog;
