// third party
import { Logger } from 'aws-amplify';
import { useState } from 'react';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import Row from 'react-bootstrap/Row';

// Local
import NetSync from './components/NetSync/NetSync';
import SystemLog from './components/SystemLog/SystemLog';
import MOHCIP from './components/MOHCIP/MOHCIP';

// SCSS
import './App.scss';

const logger = new Logger('App.js');

const links = [

  { key: 'services', value: 'Services' },
  { key: 'systemlog', value: 'SystemLog' },
  { key: 'netsync', value: 'NetSync' },
  { key: 'mohcip', value: 'MOH CIP' },
];

function App() {

  const [activeKey, setActiveKey] = useState('mohcip');

  const onSelect = (eventKey) => {

    try {

      setActiveKey(eventKey);
    }
    catch (e) {

      logger.error(e);
    }
  };

  logger.debug('rendered');

  return (

    <>

      <Navbar bg="sinirji-green" variant="dark" expand="lg" collapseOnSelect>

        <Container fluid>
          <Navbar.Brand>Sinirji Status</Navbar.Brand>

          <Navbar.Toggle aria-controls="basic-navbar-nav" />

          <Navbar.Collapse id="basic-navbar-nav">

            <Nav className="me-auto" activeKey={activeKey} onSelect={onSelect}>

              {links.map((l, i) => <Nav.Link key={i} eventKey={l.key}>{l.value}</Nav.Link>)}
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>

      <Container fluid>

        <Row>

          <Col className="pt-1">

            <ServiceSwitch activeKey={activeKey} />
          </Col>
        </Row>
      </Container>
    </>
  );
}

function ServiceSwitch({ activeKey }) {

  switch (activeKey) {

    case 'netsync':

      return <NetSync />;

    case 'systemlog':

      return <SystemLog />;

    case 'mohcip':

      return <MOHCIP />;

    case 'services':

      return <Services />;

    default:

      logger.log(`ServiceSwitch - invalid activeKey - ${activeKey}`);

      return 'Contact Support';
  }
}

function Services() {

  return (

    <div>

      <h1>Services</h1>
    </div>
  );
}

export default App;
